import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Checklist = makeShortcode("Checklist");
const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <h1>{`Content Design Guide`}</h1>
    <p>{`These are best practices for designing content. This advice won't necessarily translate well to more editorial content but should help when writing or updating any pages intended for life on the community-development platform.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.notion.so/Content-design-guide-ec389e89a9264ba2b206873f8f3aa714"
      }}>{`Originally crafted with 💚  by Ryan Cordell`}</a></p>
    <h3>{`Pre- Publish Checklist`}</h3>
    <Checklist mdxType="Checklist">
      <p>{`Removed/explained all acronyms`}</p>
      <p>{`Removed any gendered pronouns`}</p>
      <p>{`All titles are written in sentence case`}</p>
      <p>{`No latin abbreviations`}</p>
      <p>{`No metaphors`}</p>
    </Checklist>
    <p><strong parentName="p">{`Images`}</strong></p>
    <Checklist mdxType="Checklist">
      <p>{`All images/videos have alt text`}</p>
      <p>{`Any text in images is also in text on the page`}</p>
    </Checklist>
    <p><strong parentName="p">{`Explain`}</strong></p>
    <Checklist mdxType="Checklist">
      <p>{`No knowledge is assumed`}</p>
      <p>{`There are links or explanations to any specialist terms`}</p>
    </Checklist>
    <p><strong parentName="p">{`Formatting and reading experience`}</strong></p>
    <Checklist mdxType="Checklist">
      <p>{`All lists are formatted as bullet points rather than prose`}</p>
      <p>{`Checked the reading age in `}<a parentName="p" {...{
          "href": "http://hemingwayapp.com"
        }}>{`hemingwayapp.com`}</a></p>
      <p>{`All numbers are written out as numbers`}</p>
      <p>{`Most links are at the end of sentences`}</p>
      <p>{`All link copy describes the destination`}</p>
      <p>{`Content is chunked up into small sections`}</p>
      <p>{`Any unnecessary passive language has been made active`}</p>
    </Checklist>
    <blockquote>
      <p parentName="blockquote">{`Note that emoji inline support is disabled for now. 😮 <- but you can insert them manually. 🤠`}</p>
    </blockquote>
    <p><strong parentName="p">{`Emoji`}</strong></p>
    <Checklist mdxType="Checklist">
      <p>{`Checked how the emojis render on different devices`}</p>
      <p>{`Checked that you're not using culturally insensitive emojis`}</p>
      <p>{`No emojis are used in place of words`}</p>
    </Checklist>
    <h3>{`Accessibility and inclusivity 🌈`}</h3>
    <h4>{`Avoid acronyms`}</h4>
    <p>{`Acronyms require a certain level of knowledge making them exclusionary to new users. The Maker community should feel like an open community not a club.`}</p>
    <ul>
      <li parentName="ul">{`Try and avoid acronyms`}</li>
      <li parentName="ul">{`If it's unavoidable, like a page dedicated to Multi-collateral Dai, be sure to write it out in full the first time you use it: Multi-collateral Dai (MCD)`}</li>
    </ul>
    <h3>{`Avoid colloquialisms and metaphors`}</h3>
    <p>{`Colloquialisms and metaphors are also exclusionary as understanding may rely on being from the same local geography as the writer.`}</p>
    <h4>{`✅ Do`}</h4>
    <p>{`Apply for hackathon funding`}</p>
    <h4>{`❌ Don't`}</h4>
    <p>{`Apply for Hackathon Funding`}</p>
    <h3>{`Avoid "e.g.", "i.e." and other latin abbreviations`}</h3>
    <p>{`These aren't accessible as screen readers don't deal very well with them. Opt for phrases like "such as" or "like" instead of the abbreviations.`}</p>
    <h3>{`Avoid big words`}</h3>
    <p>{`Another hangover of our schooling days is the tendency to try and sound clever by using big and fancy words. But you don't want your web copy to read like a Victorian author. Always question whether there is a simpler alternative in your writing.`}</p>
    <h4>{`✅ DO`}</h4>
    <ul>
      <li parentName="ul">{`Buy`}</li>
      <li parentName="ul">{`Mix`}</li>
      <li parentName="ul">{`Agree`}</li>
    </ul>
    <h4>{`❌ DON'T`}</h4>
    <ul>
      <li parentName="ul">{`Purchase`}</li>
      <li parentName="ul">{`Amalgamation`}</li>
      <li parentName="ul">{`Acquiesce`}</li>
    </ul>
    <h3>{`Avoid gendered pronouns`}</h3>
    <p>{`If you need to refer to a "his", "her" or "their", use "their" for the most inclusive content. This shouldn't come up too often if you're addressing the user as "you".`}</p>
    <h3>{`Explain 💬`}</h3>
    <h4>{`Explain blockchainisms and financial jargon`}</h4>
    <p>{`It's fair to assume that some of the terminology on the site may be highly technical or require a lot of financial understanding. Don't assume that all users are familiar with this.`}</p>
    <p>{`If a concept can't be explained using more familiar terminology, add an explanation or a link to an explanation`}</p>
    <ul>
      <li parentName="ul">{`You can generate Dai using a vault. Vaults are a Maker product that allow you to deposit Eth and withdraw Dai.`}</li>
      <li parentName="ul">{`You can generate Dai using a `}<a parentName="li" {...{
          "href": "/learn/vaults"
        }}>{`vault`}</a>{`.`}</li>
    </ul>
    <h3>{`Explain images`}</h3>
    <p>{`If you're using an image that features text, like a process diagram ensure that the content is written in a machine-readable format. Screen readers can't detect words in an image and neither will search engines.`}</p>
    <p>{`All images should also have alt text that describes the image.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/Logo-Dai-4x.png",
        "alt": "The Dai logo"
      }}></img></p>
    <p><em parentName="p">{`Alt text`}</em>{`: The Dai logo`}</p>
    <h3>{`Formatting and reading experience ✏️`}</h3>
    <h3>{`Favor active language over passive language`}</h3>
    <ul>
      <li parentName="ul">{`"Generate Dai" vs "Dai can be generated"`}</li>
    </ul>
    <Callout mdxType="Callout">
      <p>{`💡 Tip: if you can add "`}<strong parentName="p">{`by bitcoiners`}</strong>{`" to the end of a sentence and it still makes sense, it's probably passive...`}</p>
    </Callout>
    <h3>{`Aim for a maximum reading age of: 9 (the lower the better)`}</h3>
    <p>{`Don't consider this as "dumbing down" your content. A lower reading age simply makes the reading experience more enjoyable and more efficient. Remember users haven't come to your site to experience your prose, they've come to complete a task or get information.`}</p>
    <ul>
      <li parentName="ul">{`Pop your content in `}<a parentName="li" {...{
          "href": "http://hemingwayapp.com"
        }}>{`hemingwayapp.com`}</a>{` to check its reading age and remove any unnecessary adverbs`}</li>
    </ul>
    <h3>{`Favour small chunks of content with clear headings`}</h3>
    <p>{`As mentioned above, users haven't come to your site to experience your prose, they've come to complete a task or get information. They'll likely scan your page looking for keywords related to their task. Short chunks of content with clear headings helps users scan.`}</p>
    <p>{`Front load your headings. Aim to give the user information they need in the heading rather than just signal that the information they need might be in that section.`}</p>
    <ul>
      <li parentName="ul">{`"Apply to `}<a parentName="li" {...{
          "href": "mailto:grants@makerdao.com"
        }}>{`grants@makerdao.com`}</a>{`" not "How to apply"`}</li>
      <li parentName="ul">{`Notice how this document has been structured to front-load the titles`}</li>
    </ul>
    <h3>{`Make sure links describe the destination`}</h3>
    <p>{`Avoid vague link text like "learn more". It's more helpful to set the user's expectations around where the link will take them.`}</p>
    <h4>{`✅ DO`}</h4>
    <p><a parentName="p" {...{
        "href": "#"
      }}>{`Meetup promotion guide`}</a></p>
    <h4>{`❌ DON'T`}</h4>
    <p><a parentName="p" {...{
        "href": "#"
      }}>{`Find out more`}</a></p>
    <h3>{`Avoid links mid-text where possible`}</h3>
    <p>{`This makes content harder to scan and can be problematic for autistic users.`}</p>
    <h4>{`✅ DO`}</h4>
    <p>{`We'll show you how to promote your meetup in our `}<a parentName="p" {...{
        "href": "#"
      }}>{`Meetup promotion guide`}</a></p>
    <h4>{`❌ DON'T`}</h4>
    <p>{`Read the `}<a parentName="p" {...{
        "href": "#"
      }}>{`Meetup promotion guide`}</a>{` to find out how to promote your meetup.`}</p>
    <h3>{`Use bullet points to split up long paragraphs/lists`}</h3>
    <p>{`Bullet point lists are a great format for parsing list information. Don't write out lists in paragraph format as it makes content far easier to miss.`}</p>
    <h4>{`✅ Do`}</h4>
    <p>{`You'll need to be familiar with:`}</p>
    <ul>
      <li parentName="ul">{`GitHub`}</li>
      <li parentName="ul">{`Markdown`}</li>
      <li parentName="ul">{`Maker`}</li>
      <li parentName="ul">{`Google docs`}</li>
    </ul>
    <h4>{`❌ DON'T`}</h4>
    <p>{`You'll need to be familiar with GitHub, Markdown, Maker and Google docs.`}</p>
    <h3>{`Use numerals`}</h3>
    <p>{`It's often thought that you should spell out numbers under 10. However, writing all numbers as numerals is better for scan readers.`}</p>
    <h4>{`✅ Do`}</h4>
    <p>{`Ryan has made 5 contributions`}</p>
    <h4>{`❌ DON'T`}</h4>
    <p>{`yan has made five contributions`}</p>
    <h3>{`Emojis 🤪`}</h3>
    <h4>{`Emojis should support not replace words`}</h4>
    <p>{`For example: don't use a money emoji (💰 ) instead of saying money.`}</p>
    <h3>{`Consider cultural implications`}</h3>
    <p>{`That friendly thumbs up emoji ( 👍 ) can actually be rude in middle eastern culture (and other places) – it means "up yours!". Another example might be the plate and cutlery emoji ( 🍽 ) which naturally biases western culture.`}</p>
    <h3>{`Check how they look on all devices`}</h3>
    <p>{`Some emojis are unrecognizable on certain devices.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/cookies-emoji.png",
        "alt": "Cookies"
      }}></img></p>
    <p>{`Are you referring to cookies or crackers?`}</p>
    <p><img parentName="p" {...{
        "src": "/images/detective.png",
        "alt": "Detective"
      }}></img></p>
    <p>{`Are you referring to a friendly detective or a shady stalker?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      